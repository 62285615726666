import React from 'react'
import {Box, Card, Container, css, Flex} from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ListItem from '@solid-ui-components/ListItem'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Divider from "@solid-ui-components/Divider";

const styles = {
    cards: {
        boxSizing: 'border-box',
        maxWidth: '190px',
        height: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 1,
        flexGrow: 0,
    }
}

const FeaturesWithPhotoBlock02 = ({
                                      content: {text, collection, buttons},
                                      reverse
                                  }) => (
    <Container sx={{position: `relative`}}>
        <Flex
            sx={{
                alignItems: [null, `center`],
                flexDirection: [
                    reverse ? `column-reverse` : `column`,
                    reverse ? `row-reverse` : `row`
                ],
                mx: [null, null, null, -4],
                gap: ['3rem', null]
            }}
        >
            <Box
                sx={{
                    textAlign: ['center', 'left'],
                    flexBasis: [`1`, `1/2`, `1/2`],
                    mx: 4
                }}>
                <ContentText content={text}/>
                {buttons && (
                    <>
                        <Divider space={3} />
                        <ContentButtons content={buttons} />
                    </>
                )}
            </Box>

            <FlexContent reverse={reverse}>

                {collection && (
                    <>
                        <Flex sx={{flexWrap: `wrap`, m: -4, p: '1rem', alignItems: 'center'}}>
                            {collection.map((props, index) => (
                                <Reveal
                                    key={`item-${index}`}
                                    effect='fadeInPop'
                                    delay={0.2 * (index + 1)}
                                    css={css({flexBasis: [`1/2`, `1/2`, `1/2`], px: [2, null]})}
                                >
                                    <Card py='3' m={'0.5rem auto'} sx={styles.cards}>
                                        <ListItem {...props} compact middle p='2'/>
                                    </Card>
                                </Reveal>
                            ))}
                        </Flex>
                    </>
                )}
            </FlexContent>
        </Flex>
        <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'}/>
    </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock02)
