import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import TabFeature from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import Stats from '@solid-ui-blocks/Stats/Block01'
import BuildBrand from "@solid-ui-blocks/FeaturesWithPhoto/Block04";
import ServicesDetails from "@solid-ui-blocks/Faq/Block02";
import Features from '@solid-ui-blocks/FeaturesWithPhoto/Block02/Block02copy'
import Companies from "@solid-ui-blocks/Companies/Block01";


const IndexPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Home'/>

            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>

            {/* Blocks */}
            <Header content={content['header-light']}/>

            <Divider space='5'/>
            <Container variant='full' sx={styles.heroContainer}>
                <Hero sx={styles.heroContent} content={content['hero']} reverse/>
            </Container>

            <Divider space='6'/>
            <Container variant='full' sx={styles.buildBrandContainer}>
                <BuildBrand content={content['build-brand-home']} reverse/>
            </Container>

            <Divider space='6'/>
            <Container variant='wide' sx={styles.tabsContainer}>
                <FeatureThree content={content['feature-three']}/>
                <Tabs variant='underline' space={0} autoplay autoplayInterval={8000}>
                    <TabFeature content={content['tab-feature-one']}/>
                    <TabFeature content={content['tab-feature-two']}/>
                </Tabs>
            </Container>

            <Divider space='5'/>
            <Services content={content['services']} reverse/>

            <Divider space='6'/>
            <ServicesDetails content={content['services-details']}/>

            <Divider space='6'/>
            <Container variant='narrow'>
                <Features content={content['features']} reverse/>
            </Container>

            <Divider space='6'/>
            <Container variant='full'>
                <Companies content={content['companies']}/>
            </Container>

            <Divider space='6'/>
            <Container variant='full'>
                <Container variant='narrow'>
                    <Stats content={content['stats']}/>
                </Container>
            </Container>

            <Divider space='6'/>
            <Container variant='narrow'>
                <Faq content={content['faq']}/>
            </Container>

            <Divider space='6'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query homepageGoconutBlockContent {
    allBlockContent(filter: { page: { in: ["goconut/home", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
